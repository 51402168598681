@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	border-radius: 50%;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(30, 136, 229);
	border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #0404ff;
}
